import React from "react";
import { Stack, Box } from "@mui/material";
import images from "../constants/images";

const Features = () => {
  return (
    <Stack
      direction="row"
      sx={{
        mt: 8,
        py: 4,
        px: { xs: 2, md: 6, lg: 12 },
        justifyContent: "space-between",
      }}
      className="scroll-container"
    >
      <Box sx={{ display: "flex", alignItems: "center", minWidth: { xs: "95%", md: "23%"} }}>
        <img src={images.trophy} className="mr" height={60} />
        <Box>
          <h5 className="top-p">Haute Qualité</h5>
          <p className="min-p">Fabriqué à partir de matériaux de qualité supérieure</p>
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", minWidth: { xs: "95%", md: "23%"} }}>
        <img src={images.guarantee} className="mr" height={60} />
        <Box>
          <h5 className="top-p">Protection de garantie</h5>
          <p className="min-p">Plus de deux ans</p>
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", minWidth: { xs: "95%", md: "23%"} }}>
        <img src={images.shipping} className="mr" height={60} />
        <Box>
          <h5 className="top-p">Livraison gratuite</h5>
          <p className="min-p">Expédition Gratuite</p>
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", minWidth: { xs: "95%", md: "23%"} }}>
        <img src={images.support} className="mr" height={60} />
        <Box>
          <h5 className="top-p">Assistance 24/7</h5>
          <p className="min-p">Support Dédié</p>
        </Box>
      </Box>
    </Stack>
  );
};

export default Features;
