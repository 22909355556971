import React, { useState, useEffect, useRef } from "react";
import { Box, Stack } from "@mui/material";
import {
  Features,
  Hero,
  NavBar,
  Product,
  Quality,
  Testimonial,
  Footer,
} from "../components";

import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { BsFillArrowRightCircleFill } from "react-icons/bs";

const Home = () => {
  const products = [
    {
      title: "Oreillers",
      des: "4 Oreillers a 3500Da",
      img: "creuse",
    },
    {
      title: "Surmatelas",
      des: "1 surmatelas Achete = orillers orthopediques cadeaux",
      img: "surConfort",
    },
    {
      title: "Matelas",
      des: "A partir de 15000Da",
      img: "matela",
    },
  ];

  const testimonials = [
    {
      name: "Malek Kawa",
      img: "tes1",
      rating: 5,
      text: "Je suis satisfaite très bonne qualité matelas couette et oreillers et je vous remercie beaucoup pour le cadeau aussi pour la livraison je vous souhaite bonne continuation et une réussite à tout vive Algérie je suis fière de mon pays ont commence à faire du beau travail bravo",
    },
    {
      name: "Salima Baballah",
      img: "tes2",
      rating: 5,
      text: "J ai reçu ce soir les 08 oreillers Je suis vraiment contente: Du service, de la ponctualité et de la qualité supérieure...merci je ferai d autres commandes prochainement",
    },
    {
      name: "Manel Kouache",
      img: "tes4",
      rating: 5,
      text: "J'ai reçu ma commande vraiment confortable merci",
    },
    {
      name: "Abdelkader Chouakri",
      img: "tes3",
      rating: 5,
      text: "Merci j'ai commandé un surmatelas et le cadeau dans un bref délais entre la commande et la réception 3 à 4 jours je suis très satisfait de la qualité et le confort merci",
    },
  ];

  const containerRef = useRef(null);
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(true);

  useEffect(() => {
    const container = containerRef.current;

    const handleScroll = () => {
      setShowLeftButton(container.scrollLeft > 0);
      setShowRightButton(
        container.scrollLeft < container.scrollWidth - container.clientWidth
      );
    };

    container.addEventListener("scroll", handleScroll);
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const scrollLeft = () => {
    containerRef.current.scrollBy({
      left: -containerRef.current.clientWidth,
      behavior: "smooth",
    });
  };

  const scrollRight = () => {
    containerRef.current.scrollBy({
      left: containerRef.current.clientWidth,
      behavior: "smooth",
    });
  };

  return (
    <Box sx={{ height: "auto" }}>
      <NavBar />
      <Hero />
      <Features />
      <Box sx={{ px: { xs: 2, md: 6, lg: 12 }, mt: 16 }}>
        <h3 style={{ textAlign: "center", fontSize: "36px", color: "#3A3A3A" }}>
          Nos Produits
        </h3>
        <Box className="container" sx={{ mt: 4 }}>
          {products.map((product, index) => (
            <Product product={product} key={index} />
          ))}
        </Box>
      </Box>
      <Quality />
      <Box sx={{ px: { xs: 2, md: 6, lg: 12 }, mt: 16 }}>
        <Box sx={{ margin: "auto", width: "65%" }}>
          <h3
            style={{ textAlign: "center", fontSize: "36px", color: "#3A3A3A" }}
            className="mb"
          >
            Témoignages de Nos Clients
          </h3>
          <p className="min-p" style={{ textAlign: "center" }}>
            Découvrez les avis authentiques de nos clients satisfaits. Rejoignez
            la qualité du sommeil qu'ils ont trouvé avec nos surmatelas.
          </p>
        </Box>
        <Box sx={{ overflow: "hidden", position: "relative"}}>
          <BsFillArrowLeftCircleFill
            className={`scroll-button left icon ${
              showLeftButton ? "show" : ""
            }`}
            onClick={scrollLeft}
          />
          <Box
            ref={containerRef}
            className="scroll-container"
            sx={{ mt: 4, display: "flex" }}
          >
            {testimonials.map((testimonial, index) => (
              <Testimonial key={index} testimonial={testimonial} />
            ))}
          </Box>
          <BsFillArrowRightCircleFill
            className={`scroll-button right icon ${
              showRightButton ? "show" : ""
            }`}
            onClick={scrollRight}
          />
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default Home;
