import React, { useEffect, useState } from "react";
import { Box, Stack } from "@mui/material";
import { Footer, NavBar } from "../components";
import { reqOrder } from "../services/sendOrder";
import { BiLoaderAlt } from "react-icons/bi";

const Mattress = () => {
  const [size, setSize] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [wilaya, setWilaya] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumber2, setPhoneNumber2] = useState("");
  const [shipping, setShipping] = useState(0);
  const [msg, setMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const wilayaOptions = [
    "01. Adrar (28)",
    "02. Chlef (35)",
    "03. Laghouat (24)",
    "04. Oum El Bouaghi (29)",
    "05. Batna (61)",
    "06. Bejaïa (52)",
    "07. Biskra (33)",
    "08. Béchar (21)",
    "09. Blida (29)",
    "10. Bouira (45)",
    "11. Tamanrasset (10)",
    "12. Tebessa (28)",
    "13. Tlemcen (53)",
    "14. Tiaret (42)",
    "15. Tizi Ouzou (67)",
    "16. Alger (33)",
    "17. Djelfa (36)",
    "18. Djijel (28)",
    "19. Sétif (60)",
    "20. Saïda (16)",
    "21. Skikda (38)",
    "22. Sidi Bel Abbès (52)",
    "23. Annaba (12)",
    "24. Guelma (34)",
    "25. Constantine (12)",
    "26. Médéa (64)",
    "27. Mostaganem (32)",
    "28. M'Sila (47)",
    "29. Mascara (46)",
    "30. Ouargla (21)",
    "31. Oran (25)",
    "32. El Bayadh (22)",
    "33. Illizi (6)",
    "34. Bordj Bou Arreridj (34)",
    "35. Boumerdès (38)",
    "36. El Tarf (24)",
    "37. Tindouf (2)",
    "38. Tissemsilt (22)",
    "39. El Oued (30)",
    "40. Khenchela (21)",
    "41. Souk Ahras (36)",
    "42. Tipaza (42)",
    "43. Mila (32)",
    "44. Aïn Defla (36)",
    "45. Naâma (12)",
    "46. Aïn Témouchent (28)",
    "47. Ghardaia (13)",
    "48. Relizane (38)",
  ];

  const prices = {
    "70/190cm": 15000,
    "80/190cm": 16000,
    "90/190cm": 17000,
    "120/190cm": 20000,
    "140/190cm": 22000,
    "160/190cm": 23000,
    "160/200cm": 24000,
    "180/200cm": 27000,
    "200/200cm": 28000,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setIsButtonDisabled(
      !(size && quantity && firstName && lastName && wilaya && phoneNumber)
    );
    if (size && prices[size]) {
      const price = prices[size];
      setTotalPrice(price * quantity);
    }
  }, [size, quantity, firstName, lastName, wilaya, phoneNumber]);

  const handleSizeChange = (event) => {
    setSize(event.target.value);
  };

  const handleQuantityChange = (event) => {
    setQuantity(parseInt(event.target.value));
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleWilayaChange = (event) => {
    setWilaya(event.target.value);
    if (event.target.value === "16. Alger (33)") {
      setShipping(0);
    } else {
      setShipping(1000);
    }
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handlePhoneNumber2Change = (event) => {
    setPhoneNumber2(event.target.value);
  };

  const sendReq = async () => {
    const reqBody = {
      firstName,
      lastName,
      phoneNumber,
      phoneNumber2,
      wilaya,
      size,
      quantity,
      total: totalPrice + shipping,
      type: "Matelas",
    };

    setIsLoading(true);
    const res = await reqOrder(reqBody);
    if (res) setMsg("La requête a été exécutée avec succès. ✔️🎉");
    if (res) setIsLoading(false);
    if (res) setIsButtonDisabled(true);
  };

  return (
    <Box>
      <NavBar />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: 12,
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          className="sale-box"
          sx={{ width: { xs: "80%", md: "50%" }, mb: 6 }}
        >
          <h2>Offres Spéciales</h2>
          <label htmlFor="size">Sélectionner la Taille :</label>
          <select
            id="size"
            name="size"
            value={size}
            onChange={handleSizeChange}
          >
            <option value="">Sélectionnez une taille</option>
            <option value="70/190cm">70/190cm</option>
            <option value="80/190cm">80/190cm</option>
            <option value="90/190cm">90/190cm</option>
            <option value="120/190cm">120/190cm</option>
            <option value="140/190cm">140/190cm</option>
            <option value="160/190cm">160/190cm</option>
            <option value="160/200cm">160/200cm</option>
            <option value="180/200cm">180/200cm</option>
            <option value="200/200cm">200/200cm</option>
          </select>

          <label htmlFor="quantity">Sélectionner la Quantité :</label>
          <input
            type="number"
            id="quantity"
            name="quantity"
            min="1"
            value={quantity}
            onChange={handleQuantityChange}
          />

          <p id="total-price">Prix Total : {totalPrice} da</p>
        </Box>
        <Box
          className="sale-box"
          sx={{ width: { xs: "80%", md: "50%" }, mb: 2 }}
        >
          <h3>Détails du Client</h3>
          <label htmlFor="firstName">Prénom :</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={firstName}
            onChange={handleFirstNameChange}
            required
          />

          <label htmlFor="lastName">Nom de Famille :</label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={lastName}
            onChange={handleLastNameChange}
            required
          />

          <label htmlFor="wilaya">Wilaya :</label>
          <select
            id="wilaya"
            name="wilaya"
            value={wilaya}
            onChange={handleWilayaChange}
            required
          >
            <option value="">Sélectionnez une wilaya</option>
            {wilayaOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <label htmlFor="phoneNumber">Numéro de Téléphone :</label>
          <input
            type="tel"
            id="phoneNumber"
            name="phoneNumber"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            required
          />
          <p id="total-price">Prix de livraison : {shipping} da</p>
          <p id="total-price">Prix Total : {totalPrice + shipping} da</p>
          <button
            class="add-button"
            disabled={isButtonDisabled || isLoading}
            onClick={() => sendReq()}
            style={{ display: "flex", alignItems: "center" }}
          >
            Passer la Commande
            {isLoading && <BiLoaderAlt className="loading" />}
          </button>
          <p className="min-p mt" style={{ color: "green", fontSize: "20px" }}>
            {msg}
          </p>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default Mattress;
