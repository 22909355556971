import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import images from "../constants/images";

const Quality = () => {
  return (
    <Box sx={{ mt: 18, px: { xs: 2, md: 6, lg: 12 } }} id="#3">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: 12,
        }}
      >
        <Box sx={{ width: { xs: "80%", md: "70%" } }}>
          <Box sx={{ textAlign: "center" }}>
            <h3
              style={{
                textAlign: "center",
                fontSize: "36px",
                color: "#3A3A3A",
              }}
              className="mb"
            >
              Qualité Supérieure
            </h3>
            <p className="min-p">
              Nous nous engageons à offrir une qualité exceptionnelle à chaque
              étape. Nos surmatelas sont conçus avec les meilleurs matériaux
              pour vous offrir le confort dont vous rêvez. Profitez d'une
              expérience de sommeil inégalée avec nos produits de qualité
              supérieure.
            </p>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "space-between" },
              alignItems: "center",
              flexDirection: { xs: "column", md: "row" },
              mt: 12,
            }}
          >
            <img src={images.first} height={380} />
            <Box
              sx={{
                flexBasis: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
                mt: { xs: 4, md: 0 },
              }}
            >
              <Box>
                <h3 className="mb high-p">
                  Qualité Supérieure pour un Confort Absolu
                </h3>
                <p className="min-p">
                  Découvrez un confort ultime avec nos oreillers en microfibre
                  silicone premium. 100% microfibre pour une expérience douce et
                  luxueuse. Traitement anti-acariens et antibactérien pour un
                  sommeil sain. Offrez-vous la perfection du confort pour des
                  nuits parfaites.
                </p>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "space-between" },
              alignItems: "center",
              flexDirection: { xs: "column", md: "row" },
              mt: 12,
            }}
          >
            <img src={images.second} height={380} />
            <Box
              sx={{
                flexBasis: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
                mt: { xs: 4, md: 0 },
              }}
            >
              <Box>
                <h3 className="mb high-p">
                  Un Confort Supérieur à Toute Épreuve
                </h3>
                <p className="min-p">
                  "Offrez-vous un confort exceptionnel avec notre sur-confort 4
                  bandes élastiques. Conçu pour empêcher le glissement du
                  surmatelas, il assure une expérience de sommeil inégalée. Sa
                  protection anti-allergie, anti-acariens et antibactérienne
                  garantit des nuits saines. Profitez d'un soutien exceptionnel
                  grâce à sa mousse D30 de 5 cm et à la microfibre silicone.
                </p>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Quality;
