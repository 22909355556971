import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Pillows from "./pages/Pillows";
import Mattress from "./pages/Mattress";
import Topper from "./pages/Topper";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/oreillers" element={<Pillows />} />
        <Route exact path="/surmatelas" element={<Topper />} />
        <Route exact path="/matelas" element={<Mattress />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
