import React from "react";
import { Stack, Box } from "@mui/material";
import { FiFacebook, FiTwitter } from "react-icons/fi";
import { BsInstagram } from "react-icons/bs";
import images from "../constants/images";

import { Link, animateScroll as scroll } from "react-scroll";

const Footer = () => {
  return (
    <Box
      sx={{
        mt: 22,
        borderTop: "2px solid #005B6E",
        pt: 8,
        px: { xs: 2, md: 4, lg: 6 },
      }}
    >
      <Stack
        sx={{
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ flexBasis: "30%" }}>
          <h3 style={{ fontSize: "32px" }}>Élevez Votre Confort de Sommeil</h3>
        </Box>
        <img src={images.logo} height={150} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: { xs: 4, md: 0 },
          }}
        >
          <Box>
            {["Accueil", "Produits", "À Propos", "Avis Clients", "Contact"].map(
              (nav, index) => (
                <span key={nav} className="nav-item">
                  <Link
                    activeClass="active"
                    to={`#${index + 1}`}
                    spy={true}
                    smooth={true}
                    offset={-160}
                    duration={0}
                  >
                    {nav}
                  </Link>
                </span>
              )
            )}
          </Box>
          <Box sx={{ display: "flex", mt: 6 }}>
            <FiFacebook className="icon mr" />
            <BsInstagram className="icon mr" />
            <FiTwitter className="icon mr" />
          </Box>
        </Box>
      </Stack>
      <Stack
        sx={{
          mt: 4,
          justifyContent: "space-between",
          py: 4,
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Box>
          {["Privacy policy", "Term of service", "Langague"].map((nav) => (
            <span key={nav} className="detail-item mr">
              {nav}
            </span>
          ))}
        </Box>
        <span className="detail-item" id="#5">
          2023 All Rights Reserved © Ibrahim.dev
        </span>
      </Stack>
    </Box>
  );
};

export default Footer;
