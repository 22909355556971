import React, { useState, useEffect, useRef } from "react";
import { Box, Stack } from "@mui/material";

import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { BsFillArrowRightCircleFill } from "react-icons/bs";

import { Link, animateScroll as scroll } from "react-scroll";

import imagess from "../constants/images";

const Hero = () => {
  const containerRef = useRef(null);
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(true);

  useEffect(() => {
    const container = containerRef.current;

    const handleScroll = () => {
      setShowLeftButton(container.scrollLeft > 0);
      setShowRightButton(
        container.scrollLeft < container.scrollWidth - container.clientWidth
      );
    };

    container.addEventListener("scroll", handleScroll);
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const scrollLeft = () => {
    containerRef.current.scrollBy({
      left: -containerRef.current.clientWidth,
      behavior: "smooth",
    });
  };

  const scrollRight = () => {
    containerRef.current.scrollBy({
      left: containerRef.current.clientWidth,
      behavior: "smooth",
    });
  };
  return (
    <Box id="#1">
      <Box sx={{ px: { xs: 2, md: 6, lg: 12 }, display: { sm: "none" } }}>
        <Box sx={{ mt: 4 }}>
          <h1 style={{ color: "#333333", fontSize: "36px" }} className="mb">
            Élevez Votre Confort de Sommeil
          </h1>
          <p className="p mb">
            Offrez à vos nuits un nouveau niveau de confort avec nos surmatelas
            de qualité supérieure. Redécouvrez la joie d'un sommeil réparateur
            et revigorant.
          </p>
          <Link
            activeClass="active"
            to="#2"
            spy={true}
            smooth={true}
            offset={-130}
            duration={0}
          >
            <button className="btn primary-btn">Acheter maintenant</button>
          </Link>
          <Box
            sx={{ display: "flex", mt: 4 }}
            ref={containerRef}
            className="scroll-container"
          >
            <img
              src={imagess.main1}
              height={300}
              style={{ marginRight: "30px" }}
            />
            <img
              src={imagess.main2}
              height={300}
              style={{ marginRight: "30px" }}
            />
            <img
              src={imagess.main3}
              height={300}
              style={{ marginRight: "30px" }}
            />
            <img
              src={imagess.main1}
              height={300}
              style={{ marginRight: "30px" }}
            />
            <img
              src={imagess.main2}
              height={300}
              style={{ marginRight: "30px" }}
            />
            <img
              src={imagess.main3}
              height={300}
              style={{ marginRight: "30px" }}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          px: { xs: 2, md: 6, lg: 12 },
          mt: 6,
          position: "relative",
          display: { xs: "none", sm: "block" },
        }}
      >
        <Box>
          <Box sx={{ position: "relative", py: 2, m: 2 }}>
            <Box sx={{ position: "absolute", right: "0" }}>
              <BsFillArrowLeftCircleFill
                className="icon sw-btn mr"
                onClick={scrollLeft}
              />
              <BsFillArrowRightCircleFill
                className="icon sw-btn"
                onClick={scrollRight}
              />
            </Box>
          </Box>

          <Box
            sx={{ display: "flex", mt: 4 }}
            ref={containerRef}
            className="scroll-container"
          >
            <img
              src={imagess.main1}
              height={500}
              style={{ marginRight: "30px" }}
            />
            <img
              src={imagess.main2}
              height={500}
              style={{ marginRight: "30px" }}
            />
            <img
              src={imagess.main3}
              height={500}
              style={{ marginRight: "30px" }}
            />
            <img
              src={imagess.main1}
              height={500}
              style={{ marginRight: "30px" }}
            />
            <img
              src={imagess.main2}
              height={500}
              style={{ marginRight: "30px" }}
            />
            <img
              src={imagess.main3}
              height={500}
              style={{ marginRight: "30px" }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            position: "absolute",
            width: "460px",
            backdropFilter: "blur(4px)",
            background: "rgba(255, 255, 255, 0.8)",
            boxShadow: "0 0 14px rgba( 31, 38, 135, 0.18)",
            top: "0",
            left: "20%",
            p: 8,
          }}
        >
          <h1 style={{ color: "#333333", fontSize: "42px" }} className="mb">
            Élevez Votre Confort de Sommeil
          </h1>
          <p className="p mb">
            Offrez à vos nuits un nouveau niveau de confort avec nos surmatelas
            de qualité supérieure. Redécouvrez la joie d'un sommeil réparateur
            et revigorant.
          </p>
          <Link
            activeClass="active"
            to="#2"
            spy={true}
            smooth={true}
            offset={-130}
            duration={0}
          >
            <button className="btn primary-btn">Acheter maintenant</button>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default Hero;
