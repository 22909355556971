import React, { useState, useRef } from "react";

import { Box, Stack } from "@mui/material";

import { RiMenu5Fill } from "react-icons/ri";
import { IoMdClose } from "react-icons/io";
import images from "../constants/images";

import { Link, animateScroll as scroll } from "react-scroll";
import { useNavigate } from "react-router-dom";

const NavBar = () => {
  const [sideBarToggle, setSideBarToggle] = useState("-100%");
  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          display: { xs: "flex", md: "none" },
          background: "#005B6E",
          justifyContent: "center",
          width: "100%",
          height: "100vh",
          left: sideBarToggle,
          top: "0",
          transition: "left 0.3s ease-in-out",
          p: 2,
          zIndex: "1000000",
        }}
      >
        <IoMdClose
          className="icon"
          style={{
            position: "absolute",
            right: "25px",
            top: "25px",
            fontSize: "36px",
          }}
          onClick={() => setSideBarToggle("-100%")}
        />
        <Stack direction="column" sx={{ mt: { xs: 5, sm: 8 } }}>
          {["Accueil", "Produits", "À Propos", "Avis Clients", "Contact"].map(
            (nav, index) => (
              <span
                key={nav}
                className="nav-item"
                style={{
                  margin: "0",
                  marginBottom: "65px",
                  fontSize: "28px",
                  color: "white",
                }}
              >
                <Link
                  activeClass="active"
                  to={`#${index + 1}`}
                  spy={true}
                  smooth={true}
                  offset={-160}
                  duration={0}
                  onClick={() => {
                    setSideBarToggle("-100%");
                  }}
                >
                  {nav}
                </Link>
              </span>
            )
          )}
        </Stack>
      </Box>
      <Stack
        direction="row"
        sx={{
          top: 0,
          py: 2,
          px: { xs: 2, md: 6, lg: 12 },
          alignItems: "center",
          zIndex: "100",
          justifyContent: "space-between",
          position: "sticky",
          borderBottom: "1px solid #005B6E",
          background: "white",
          zIndex: "10000",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
          <img src={images.logo} height={85} onClick={() => navigate("/")} />
        </Box>
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            flexGrow: 1,
            justifyContent: "center",
          }}
        >
          {["Accueil", "Produits", "À Propos", "Avis Clients", "Contact"].map(
            (nav, index) => (
              <span key={index} className="nav-item">
                <Link
                  activeClass="active"
                  to={`#${index + 1}`}
                  spy={true}
                  smooth={true}
                  offset={-160}
                  duration={0}
                >
                  {nav}
                </Link>
              </span>
            )
          )}
        </Box>
        <Box sx={{ display: { md: "none" } }}>
          <RiMenu5Fill className="icon" onClick={() => setSideBarToggle("0")} />
        </Box>
      </Stack>
    </>
  );
};

export default NavBar;
