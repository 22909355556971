import logo from "../assessts/logo.jpg"
import main1 from "../assessts/main1.jpg"
import main2 from "../assessts/main2.jpg"
import main3 from "../assessts/main3.jpg"
import main4 from "../assessts/main4.jpg"
import trophy from "../assessts/trophy.svg"
import shipping from "../assessts/shipping.svg"
import guarantee from "../assessts/guarantee.svg"
import support from "../assessts/customer-support.svg"
import creuse from "../assessts/creuse.jpg"
import matela from "../assessts/Matela.jpg"
import surConfort from "../assessts/surConfort.jpg"
import first from "../assessts/first.jpg"
import second from "../assessts/second.jpg"
import tes1 from "../assessts/tes1.jpg"
import tes2 from "../assessts/tes2.jpg"
import tes3 from "../assessts/tes3.jpg"
import tes4 from "../assessts/tes4.jpg"


export default {
    logo,
    main1,
    main2,
    main3,
    main4,
    trophy,
    shipping,
    guarantee,
    support,
    creuse,
    matela,
    surConfort,
    first,
    second,
    tes1,
    tes2,
    tes3,
    tes4,
}