import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import images from "../constants/images";
import { useNavigate } from "react-router-dom";

const Product = ({ product }) => {
  const navigate = useNavigate()
  return (
    <Box
      sx={{
        background: "#F4F5F7",
        p: 2,
        boxShadow: "0 0 10px rgba( 31, 38, 135, 0.18)",
        cursor: "pointer",
      }}
      className="product-box"
      id="#2"
      onClick={() => {
        if(product.title === "Oreillers") navigate("/oreillers")
        else if(product.title === "Surmatelas") navigate("/surmatelas")
        else if(product.title === "Matelas") navigate("/matelas")
      }}
    >
      <img
        src={images[product.img]}
        style={{
          display: "inline-block",
          objectFit: "cover",
          width: "100%",
          height: "220px",
          display: "flex",
          justifyContent: "start",
          alignItems: "top",
          objectPosition: "left top",
        }}
        className="project-img"
      />
      <h5 className="top-p mt">{product.title}</h5>
      <p className="min-p" style={{ fontSize: "14px" }}>
        {product.des}
      </p>
      <button class="buy-button btn primary-btn" style={{ width: "30%", padding: "15px 28px" }}>
        Acheter
      </button>
    </Box>
  );
};

export default Product;
